.XfersRedoc {
  display: block;
  flex-direction: row;
  height: 100vh;
}

.country-header {
  display: flex;
  align-items: center;
  justify-content: left;
}

.country-header-title{
  display: inline;
  margin: auto;
  align-items: center;
  font-size: 3rem;
  font-size: 2.5vw;
  word-wrap: break-word;
  justify-content: center;
}

.logo-wrapper{
  width: 260px;
  height: 128px;
  background-color: #fafafa;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-wrapper{
  width: 40%;
  padding-left: 40px;
  margin-right: 1vw;
  text-align: left;
}

.empty-block{
  display:flex;
  width: 260px;
  height: 128px;
}

.up-button{
  position: fixed;
  z-index: 2;
  left: 90%;
  top: 85%;
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}
