.wrap{
  display: block;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  background: -webkit-radial-gradient(right bottom , ellipse cover , rgb(114, 237, 242) , rgb(81, 81, 229) 100%);
}

.title{
    margin: 0;
    width: 100%;
    text-align: center;    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title h1{
  color: white;
  font-size: 6rem;
  display: inline-block;
}

.dropdown-api-version{
    width: 156px;
    padding: 5px;
}

.dropdown-country{
    width: 156px;
    padding: 5px;

}

.buttons{
    display: flex;
    justify-content: center;
}